import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },



    {
        path: '',
        component: PagesComponent,
        children: [
            { path: 'Admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },
            { path: 'Dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            // { path: 'Employee', component: EmployeesLoginComponent },
        ]
    },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    { path: '**', component: NotFoundComponent }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes
            //, {
            //    //preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            //    //relativeLinkResolution: 'legacy',
            //    useHash: true
            //}
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
