import { Menu } from './menu.model';

export const verticalMenuItems = [

  //new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
  new Menu(2, 'Departments', 'Admin/Departments', null, 'wrench', null, false, 0),
  new Menu(3, 'Directory', 'Admin/Directories', null, 'file-o', null, false, 0),
  new Menu(4, 'Users', 'Admin/Users', null, 'registered', null, false, 0),
 
  
]


export const EmployeeMenu = [
  new Menu(3, 'Directory', 'Admin/Directories', null, 'file-o', null, false, 0),
 // new Menu(4, 'Users', 'Admin/Users', null, 'registered', null, false, 0),

]

export const Hod = [
 // new Menu(3, 'Fileupload', 'Admin/Fileupload', null, 'file-o', null, false, 0),  
  new Menu(3, 'Directory', 'Admin/Directories', null, 'file-o', null, false, 0),
  new Menu(4, 'Users', 'Admin/Users', null, 'registered', null, false, 0),

 
]

export const horizontalMenuItems = [
  //new Menu(1, 'Dashboard', '/Dashboard', null, 'tachometer', null, false, 0),
  new Menu(2, 'Departments', 'Admin/Departments', null, 'wrench', null, false, 0),
  new Menu(3, 'Directory', 'Admin/Directories', null, 'file-o', null, false, 0),
  new Menu(4, 'Users', 'Admin/Users', null, 'registered', null, false, 0),

]
