<nav class="navbar box-shadow app-header" [class.fixed-top]="settings.theme.navbarIsFixed" [class.logo-visible]="showInfoContent">
  <div class="d-flex flex-column w-100">


    <!--<div id="info-content" class="panel-collapse collapse">
      <a class="close" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" [class.show]="showInfoContent">
        <span aria-hidden="true">&times;</span>
      </a>
      <div class="row align-items-center">
        <div class="col-md-5">
         
        </div>
        <div class="col-md-7">
          <div class="row py-4">
            <div class="col-lg-7 col-sm-8 col-12 left-border">
              <div class="info w-280p mx-auto text-center">
               
              </div>
            </div>
            <div class="col-lg-5 col-sm-4 left-border d-none d-sm-block">
                         </div>
          </div>
        </div>
      </div>
    </div>-->
    <div class="d-flex justify-content-start align-items-center top-navbar" >

      <!--<img src="assets\img\white-logo.png" style="height:42px;width:143px;" />

  <a class="logo" routerLink="/" (click)="closeSubMenus()">
   </a>-->
      <img src="../../../../assets/img/white-logo.png" style="width:178px;"/>
      <!--<span class="navbar-text menu-icon transition ml-3 mr-3" [ngClass]="{'open': settings.theme.showMenu}"
        (click)="settings.theme.showMenu = !settings.theme.showMenu">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </span>-->
      <!--<div class="ml-2 mr-2 d-none d-sm-block">
       <a id="info-content-icon" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" class="d-flex" [class.show]="showInfoContent">
          <div [@showInfo]="showInfoContent" class="triangle"></div>
      </a>
  </div>

  <form class="app-search d-none d-md-inline-block ml-3">
      <i class="fa fa-search"></i>
      <input type="text" placeholder="Type to search..." class="form-control">
  </form>-->
      <!--<app-favorites class="d-none d-lg-inline-block"></app-favorites>-->

      <div class="top-rigth-icons ml-auto">
      <!-- <button (click)="navigatemenu2()" *ngIf="Logindet.RoleID==1" class="button-1">Departments</button>-->
        <button (click)="navigatemenu()" class="button-1">Directories</button>
        <button (click)="navigatemenu1()" *ngIf="Logindet.RoleID==1 ||Logindet.RoleID==2" class="button-1">Users</button>
        <!--<span class="d-none d-sm-inline-block">
        <app-flags-menu></app-flags-menu>
    </span>
    <span class="d-none d-sm-inline-block">
        <app-fullscreen></app-fullscreen>
    </span>
    <span class="d-none d-sm-inline-block">
        <app-applications></app-applications>
    </span>
    <span class="d-none d-sm-inline-block">
        <app-messages></app-messages>
    </span>-->
        <!--<span class="d-none d-sm-inline-block pl-2 pr-2" (click)="settings.theme.showSideChat = !settings.theme.showSideChat">
        <i class="fa fa-commenting-o" aria-hidden="true"></i>
    </span>-->
        <span class="d-none d-sm-inline-block">
          <!--<app-user-menu></app-user-menu>-->
        </span>
        <span class="pl-2 pr-2">
          Hi&nbsp;<b>{{Logindet.FirstName}} {{Logindet.LastName}}</b>({{Logindet.RoleName}})&nbsp;<i class="fa fa-sign-out" aria-hidden="true" (click)="logout()"></i>

          <!--Hi&nbsp;<b>Admin</b>&nbsp;<i class="fa fa-sign-out" aria-hidden="true" (click)="logout()"></i>-->
        </span>
        <!--<span class="pl-2 pr-2">
        <a routerLink="/login">
            <i class="fa fa-sign-out" aria-hidden="true"></i>
        </a>
    </span>-->
      </div>

    </div>

    <div class="horizontal-menu-outer" *ngIf="settings.theme.menu == 'horizontal'">
      <app-horizontal-menu *ngIf="showHorizontalMenu"  [menuItems]="menuItems"></app-horizontal-menu>
      <app-vertical-menu *ngIf="!showHorizontalMenu"  [menuItems]="menuItems"></app-vertical-menu>
  </div>

  </div>
</nav>
